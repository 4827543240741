/* import { fairtouchConfig } from './../config'
 */
import { Auth0Provider } from '@auth0/auth0-react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import i18n from 'i18next'
import React, { useEffect } from 'react'
import { ContentContainer, ExternalContent, Furioos } from '../components'
import { PageFrame, PageParams } from '../components/organisms/pageFrame'
import { mq } from '../utility'

const Index = (params: PageParams) => {
  const {
    pageContext: { lang },
  } = params

  const [loaded, setLoaded] = React.useState(false)
  useEffect(() => {
    i18n.changeLanguage(lang)
    setLoaded(true)
  }, [])

  if (lang === '') {
    if (typeof window !== 'undefined') {
      navigate(`/en/${document.location.search}`)
    }

    return <></>
  }

  const redirectUri = typeof window !== 'undefined' ? window.location.origin : ""

  return (
    <Auth0Provider
    domain={process.env.GATSBY_REACT_APP_AUTH_DOMAIN}
    clientId={process.env.GATSBY_REACT_APP_AUTH_CLIENT_ID}
      redirectUri={redirectUri}
    >
      <PageFrame {...params} pageName="index" isTop>
        <>
          <FairtouchContentContainer fullwidth>
            <FairtouchContainer> 
              {/* <ExternalContent
                uri={`https://portal.furioos.com/embed/250.998?whiteLabel=true&hideTitle=true`}
              /> */}
              <Furioos />
            </FairtouchContainer>
          </FairtouchContentContainer>
        </>
      </PageFrame>
    </Auth0Provider>
  )
}

export default Index

const FairtouchContentContainer = styled(ContentContainer)(
  ({ theme: { colors, grid } }) => `
  margin-top:70px;

  ${mq[3]}{
    margin-top:155px;
  }
  ${mq[4]}{
    margin-top:145px;
  }
`
)

const FairtouchContainer = styled.div(
  ({ theme: { colors, grid } }) => `
  height:calc(100vh - 70px);
  ${mq[3]}{
    height:calc(100vh - 105px);
  }
   ${mq[4]}{
    height:calc(100vh - 95px);
  }
  width:100%;
`
)
